<template>
  <div class="container">
    <div class="static-page">
      <div class="not-found">
        <img src="@/assets/images/static-page/not-found/not-found.png">
        <label>Страница не найдена</label>
        <router-link to="/" class="btn btn-info">Перейти на главную</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'not-found',
  data: function () {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>
